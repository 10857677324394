import { Box, Typography } from '@mui/material'
import { useSSE } from 'react-hooks-sse'
import { DeviceEvent } from '../../types'
import DeviceButton from './DeviceButton'

const showMACinDeviceButton = false

interface IProps {
  soni_devices: number[]
}

export const SoniDevices = ({ soni_devices }: IProps) => {
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter((device) => device.type === 'soni')

  const filteredDevices = devices?.filter((device) => soni_devices?.includes(device.id)) || []

  return (
    <>
      {filteredDevices.map((device) => (
        <Box key={device.id} m={2}>
          <DeviceButton viewMode="large-icon" device={device} size={72} />
          <Typography variant="caption" display="flex" justifyContent="center">
            {device.description}
          </Typography>
          {device.group_id === null && (
            <Typography variant="caption" display="flex" justifyContent="center" color="error">
              MISSING ZONE
            </Typography>
          )}
          {showMACinDeviceButton ? (
            <Typography variant="caption" display="flex" justifyContent="center">
              {device.device_id}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      ))}
    </>
  )
}
