import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  settingsRequest,
  settingsSuccess,
  settingsError,
  settingsCreateRequest,
  settingsCreateSuccess,
  settingsCreateError,
  settingsUpdateRequest,
  settingsUpdateSuccess,
  settingsUpdateError,
  settingsDeleteRequest,
  settingsDeleteSuccess,
  settingsDeleteError,
  settingsCleanSuccessRequest,
  settingsCleanErrorRequest,
  settingsCleanRequest,
  settingsDetailsRequest,
  settingsDetailsSuccess,
  settingsDetailsError,
} from '../actions/settings/actions'
import { Setting, PaginatedResponse } from '../../../types'
import { deletedItem } from '../../../hip/types'

interface SettingsState {
  isLoading: boolean
  error: string | null
  success: string
  settings: {
    page: number | null
    limit: number | null
    pages: number | null
    total: number | null
    items: Setting[]
  }
  setting: Setting
}

export const initialState = (): SettingsState => ({
  isLoading: false,
  settings: {
    page: null,
    limit: null,
    pages: null,
    total: null,
    items: [],
  },
  success: '',
  error: '',
  setting: null,
})

export const settingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(settingsRequest, (state) => {
      state.settings = initialState().settings
      state.isLoading = true
    })
    .addCase(settingsSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const { page, limit, pages, total, items } = payload
      state.settings = {
        page,
        limit,
        pages,
        total,
        items: items as Setting[],
      }
      state.isLoading = false
    })
    .addCase(settingsError, (state, { payload }) => {
      console.log('settingsError', payload)
      state.settings = initialState().settings
      state.isLoading = false
      state.error = payload
    })
    .addCase(settingsCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(settingsCreateSuccess, (state) => {
      state.isLoading = false
      state.success = 'add_success'
    })
    .addCase(settingsCreateError, (state, { payload }) => {
      console.log('settingsCreateError', payload)
      state.isLoading = false
      state.error = payload
    })
    .addCase(settingsDetailsRequest, (state) => {
      state.isLoading = true
      state.setting = initialState().setting
    })
    .addCase(settingsDetailsSuccess, (state, { payload }: PayloadAction<Setting>) => {
      state.setting = payload
      state.isLoading = false
    })
    .addCase(settingsDetailsError, (state, { payload }) => {
      console.log('settingsDetailsError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(settingsUpdateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(settingsUpdateSuccess, (state) => {
      state.success = 'update_success'
      state.isLoading = false
    })
    .addCase(settingsUpdateError, (state, { payload }) => {
      console.log('settingsUpdateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(settingsDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(settingsDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      const currentSettings = state.settings
      const updateSettings = {
        ...currentSettings,
        total: currentSettings.total ? currentSettings.total - 1 : 0,
        items: currentSettings.items.filter((setting) => setting.id !== Number(payload.id)),
      }
      state.settings = updateSettings
      state.success = 'delete_success'
      state.isLoading = false
    })
    .addCase(settingsDeleteError, (state, { payload }) => {
      console.log('settingsDeleteError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(settingsCleanRequest, (state) => {
      state.settings = initialState().settings
    })
    .addCase(settingsCleanSuccessRequest, (state) => {
      state.success = ''
    })
    .addCase(settingsCleanErrorRequest, (state) => {
      state.error = ''
    })
})
