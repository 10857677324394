export const SETTINGS_REQUEST = '@api/SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = '@api/SETTINGS_SUCCESS'
export const SETTINGS_ERROR = '@api/SETTINGS_ERROR'

export const SETTINGS_CREATE_REQUEST = '@api/SETTINGS_CREATE_REQUEST'
export const SETTINGS_CREATE_SUCCESS = '@api/SETTINGS_CREATE_SUCCESS'
export const SETTINGS_CREATE_ERROR = '@api/SETTINGS_CREATE_ERROR'

export const SETTINGS_DETAILS_REQUEST = '@api/SETTINGS_DETAILS_REQUEST'
export const SETTINGS_DETAILS_SUCCESS = '@api/SETTINGS_DETAILS_SUCCESS'
export const SETTINGS_DETAILS_ERROR = '@api/SETTINGS_DETAILS_ERROR'

export const SETTINGS_UPDATE_REQUEST = '@api/SETTINGS_UPDATE_REQUEST'
export const SETTINGS_UPDATE_SUCCESS = '@api/SETTINGS_UPDATE_SUCCESS'
export const SETTINGS_UPDATE_ERROR = '@api/SETTINGS_UPDATE_ERROR'

export const SETTINGS_DELETE_REQUEST = '@api/SETTINGS_DELETE_REQUEST'
export const SETTINGS_DELETE_SUCCESS = '@api/SETTINGS_DELETE_SUCCESS'
export const SETTINGS_DELETE_ERROR = '@api/SETTINGS_DELETE_ERROR'

export const SETTINGS_CLEAN_REQUEST = '@api/SETTINGS_CLEAN_REQUEST'
export const SETTINGS_CLEAN_SUCCESS_REQUEST = '@api/SETTINGS_CLEAN_SUCCESS_REQUEST'
export const SETTINGS_CLEAN_ERROR_REQUEST = '@api/SETTINGS_CLEAN_ERROR_REQUEST'
