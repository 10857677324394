import { useState } from 'react'
import { Box, Fab, Modal, Paper, Typography } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import HipButton from '../components/HipButton'
import { HipIconType } from '../types'

const styles = {
  floatingButton: {
    margin: 4,
    position: 'fixed' as const,
    right: 20,
    bottom: 20,
  },
  modalContent: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '15px',
    maxWidth: '800px',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  layout: {
    desktop: {
      display: 'flex',
      flexDirection: 'row' as const,
      flexWrap: 'wrap' as const,
      gap: '10px',
      justifyContent: 'center',
    },
    mobile: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
      gap: '10px',
    },
  },
}

const info = [
  { fill: '#354652', image: 'ERROR', name: 'Error' },
  { fill: '#979797', image: 'OK', name: 'End of service or stand by' },
  { fill: '#43aaf3', image: 'NURSE', name: 'Nurse at room' },
  { fill: '#df007c', image: 'CODE_BLUE', name: 'CODE BLUE ALARM' },
  { fill: '#5c2582', image: 'CODE_BLUE_NA', name: 'CODE BLUE UNATTENDED' },
  { fill: '#43aaf3', image: 'ROOM_READY', name: 'Room ready' },
  { fill: '#f9b03d', image: 'BED_CALL', name: 'Bed call' },
  { fill: '#5c2582', image: 'IGNORED', name: 'Bed call unattended' },
  { fill: '#f05a25', image: 'MESSAGE_SENT', name: 'Calling room or Call established' },
  { fill: '#43aaf3', image: 'ASSIGNED', name: 'Nurse assigned' },
  { fill: '#f9b03d', image: 'BATHROOM_CALL', name: 'Bathroom call' },
  { fill: '#5c2582', image: 'BATHROOM_IGNORED', name: 'Bathroom call unattended' },
  { fill: '#f05a25', image: 'BATH_MESSAGE_SENT', name: 'Bathroom call established' },
  { fill: '#f9b03d', image: 'CLEANING_REQ', name: 'Cleaning request' },
  { fill: '#43aaf3', image: 'CLEANING', name: 'Room under cleaning' },
  { fill: '#f9b03d', image: 'ROOM_SERVICE_REQ', name: 'Room service request' },
  { fill: '#43aaf3', image: 'ROOM_SERVICE', name: 'Room service' },
  { fill: '#43aaf3', image: 'MAINTENANCE', name: 'Room under maintenance' },
]

interface IProps {
  isDesktopMode: boolean
}

const IconsInfo = ({ isDesktopMode }: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => setIsModalOpen((prev) => !prev)

  return (
    <>
      <Fab sx={styles.floatingButton} onClick={toggleModal}>
        <HelpIcon color="primary" />
      </Fab>
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Paper
          sx={{ ...styles.modalContent, width: isDesktopMode ? 'fit-content' : 'max-content' }}
        >
          <Box
            sx={isDesktopMode ? styles.layout.desktop : styles.layout.mobile}
            data-e2e="room-list"
          >
            {info.map((button, index) => (
              <Box
                key={index}
                sx={{
                  margin: '5px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <HipButton
                  viewMode="large-icon"
                  image={button.image as HipIconType}
                  fill={button.fill}
                />
                <Typography variant="caption">{button.name}</Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Modal>
    </>
  )
}

export default IconsInfo
