import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Privileges } from '../../enum'
import usePrivilege from '../../hooks/usePrivilege'
import { CircularProgress, Grid } from '@mui/material'
import { BedroomChild } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import {
  roomDetailRequest,
  roomCleanErrorRequest,
  roomCleanSuccessRequest,
} from '../actions/rooms/actions'
import { roomsStates } from '../store/selectors/rooms'
import DetailContainer from '../components/DetailContainer'
import RoomForm from './RoomForm'
import { useSnackbar } from 'notistack'

const RoomDetail = () => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { room, error, success, isLoading } = useSelector(roomsStates)

  useEffect(() => {
    dispatch(roomDetailRequest(Number(id)))
  }, [])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(roomCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(roomCleanErrorRequest())
    }
  }, [success, error])

  const atrToRender = {
    id: room.id,
    orderId: room.orderId,
    roomNo: room.roomNo,
    floor: room.floor,
    name: room.name,
  }

  const objectEntries = Object.entries(atrToRender)

  return (
    <CommonContent title={t('room_detail')} titleIcon={<BedroomChild style={{ color: 'white' }} />}>
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto', alignSelf: 'center' }} />
      ) : (
        <>
          <Grid container direction={'column'} alignContent={'center'} spacing={2} mb={4}>
            <Grid item xs={6}>
              <DetailContainer title={'room_detail'} object={objectEntries} data-e2e="rooms-item" />
            </Grid>
            <Grid item>
              {room.rcus.map((rcu) => (
                <DetailContainer
                  key={rcu.id}
                  title={'device_detail'}
                  object={Object.entries(rcu)}
                  data-e2e={`rooms-rcu-item-${rcu.id}`}
                />
              ))}
            </Grid>
          </Grid>
        </>
      )}
      {hasPrivilege(Privileges.EDIT_ROOMS) && <RoomForm room={room} isEdit={true} />}
    </CommonContent>
  )
}

export default RoomDetail
