import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { IDeviceType, IpDevice, PaginatedResponse } from '../../../types'
import {
  ipdevicesRequest,
  ipdevicesSuccess,
  ipdevicesError,
  devicesTypesSuccess,
  devicesRequest,
  devicesSuccess,
  devicesError,
  deviceDetailRequest,
  deviceDetailSuccess,
  deviceDetailError,
  deviceCreateSuccess,
  deviceCreateError,
  deviceUpdateRequest,
  deviceUpdateSuccess,
  deviceUpdateError,
  deviceDeleteRequest,
  deviceDeleteSuccess,
  deviceDeleteError,
  // volumeUpdateSuccess,
  // volumeUpdateError,
  deviceCleanRequest,
  deviceCleanErrorRequest,
  deviceCleanSuccessRequest,
  deviceCreateRequest,
  availableSonisRequest,
  availableSonisSuccess,
  availableSonisError,
} from '../actions/devices/actions'
import { deletedItem } from '../../../hip/types'
import { Soni } from '../../api/types'

interface DevicesState {
  types: IDeviceType[]
  device: IpDevice
  devices: {
    page: number | null
    limit: number | null
    pages: number | null
    total: number | null
    items: IpDevice[]
  }
  ipDevices: {
    page: number | null
    limit: number | null
    pages: number | null
    total: number | null
    items: IpDevice[]
  }
  availableSonis: Soni[]
  isLoading_dev: boolean
  success: string
  error: string
}

export const initialState = (): DevicesState => ({
  types: [],
  device: null,
  devices: {
    page: null,
    limit: null,
    pages: null,
    total: null,
    items: [],
  },
  ipDevices: {
    page: null,
    limit: null,
    pages: null,
    total: null,
    items: [],
  },
  availableSonis: [],
  isLoading_dev: false,
  success: '',
  error: '',
})

export const devicesReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(ipdevicesRequest, (state) => {
      state.ipDevices = initialState().ipDevices
      state.isLoading_dev = true
    })
    .addCase(ipdevicesSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const { page, limit, pages, total, items } = payload
      state.ipDevices = {
        page,
        limit,
        pages,
        total,
        items: items as IpDevice[],
      }
      state.isLoading_dev = false
    })
    .addCase(ipdevicesError, (state, { payload }) => {
      console.error('devicesReducer - ipdevicesError', payload)
      state.ipDevices = initialState().ipDevices
      state.isLoading_dev = false
      state.error = payload
    })
    .addCase(devicesTypesSuccess, (state, { payload }) => {
      state.types = payload
    })
    .addCase(devicesRequest, (state) => {
      state.devices = initialState().devices
      state.isLoading_dev = true
    })
    .addCase(devicesSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const { page, limit, pages, total, items } = payload
      state.devices = {
        page,
        limit,
        pages,
        total,
        items: items as IpDevice[],
      }
      state.isLoading_dev = false
    })
    .addCase(devicesError, (state, { payload }) => {
      console.error('devicesReducer - devicesError', payload)
      state.error = payload
      state.devices = initialState().devices
      state.isLoading_dev = false
    })
    .addCase(availableSonisRequest, (state) => {
      state.availableSonis = []
    })
    .addCase(availableSonisSuccess, (state, { payload }: PayloadAction<Soni[]>) => {
      const availableSonis = payload
      state.availableSonis = availableSonis
    })
    .addCase(availableSonisError, (state, { payload }) => {
      console.error('devicesReducer - availableSonisError', payload)
      state.error = payload
      state.availableSonis = []
    })
    .addCase(deviceDetailRequest, (state) => {
      state.device = initialState().device
      state.isLoading_dev = true
    })
    .addCase(deviceDetailSuccess, (state, { payload }) => {
      state.device = payload
      state.isLoading_dev = false
    })
    .addCase(deviceDetailError, (state, { payload }) => {
      console.error('devicesReducer - deviceDetailError', payload)
      state.error = payload
      state.device = initialState().device
      state.isLoading_dev = false
    })
    .addCase(deviceCreateRequest, (state) => {
      state.isLoading_dev = true
    })
    .addCase(deviceCreateSuccess, (state) => {
      state.isLoading_dev = false
      state.success = 'add_success'
    })
    .addCase(deviceCreateError, (state, { payload }) => {
      console.error('devicesReducer - deviceCreateError', payload)
      state.isLoading_dev = false
      state.error = payload.message
    })
    .addCase(deviceUpdateRequest, (state) => {
      state.isLoading_dev = true
    })
    .addCase(deviceUpdateSuccess, (state) => {
      state.isLoading_dev = false
      state.success = 'update_success'
    })
    .addCase(deviceUpdateError, (state, { payload }) => {
      console.error('devicesReducer - deviceUpdateError', payload)
      state.error = payload.message
      state.isLoading_dev = false
    })
    .addCase(deviceDeleteRequest, (state) => {
      state.isLoading_dev = true
    })
    .addCase(deviceDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      const currentDevice = state.ipDevices
      const updatedDevice = {
        ...currentDevice,
        total: currentDevice.total ? currentDevice.total - 1 : 0,
        items: currentDevice.items.filter((device) => device.id !== Number(payload.id)),
      }
      state.ipDevices = updatedDevice
      state.success = 'delete_success'
      state.isLoading_dev = false
    })
    .addCase(deviceDeleteError, (state, { payload }) => {
      console.error('devicesReducer - deviceDeleteError', payload)
      state.error = payload.message
      state.isLoading_dev = false
    })
    // VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
    // .addCase(volumeUpdateSuccess, (state, { payload }) => {
    //   const updatedItems = state.devices.items.map((device) =>
    //     device.id === payload.id ? payload : device,
    //   )
    //   state.devices = { ...state.devices, items: updatedItems as IpDevice[] }

    //   let successMessage = 'volume'
    //   if (payload.volume === 0) {
    //     successMessage = 'vol_min'
    //   } else if (payload.volume === 31) {
    //     successMessage = 'vol_max'
    //   } else {
    //     successMessage += ` ${payload.volume}`
    //   }

    //   state.success = successMessage
    // })
    // .addCase(volumeUpdateError, (state, { payload }) => {
    //   console.error('devicesReducer - volumeUpdateError', payload)
    //   state.error = payload
    //   state.devices = initialState().devices
    // })
    .addCase(deviceCleanRequest, (state) => {
      state.device = initialState().device
    })
    .addCase(deviceCleanSuccessRequest, (state) => {
      state.success = ''
    })
    .addCase(deviceCleanErrorRequest, (state) => {
      state.error = ''
    })
})
