import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { Provider } from 'react-redux'
// import { store } from './profiles/redux/store'
import './index.css'
import { ThemeProvider } from '@mui/material'
import theme from './styles/theme'
import store from './common/store/store'
import ProfileProvider from './components/ProfileProvider'
// import PushNotificationsProvider from './components/PushNotificationsProvider'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            action={(snackbarId) => <button onClick={() => closeSnackbar(snackbarId)}>X</button>}
          >
            <ProfileProvider>
              {/* <PushNotificationsProvider> */}
              <App />
              {/* </PushNotificationsProvider> */}
            </ProfileProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
