export const IPDEVICES_REQUEST = '@api/IPDEVICES_REQUEST'
export const IPDEVICES_SUCCESS = '@api/IPDEVICES_SUCCESS'
export const IPDEVICES_ERROR = '@api/IPDEVICES_ERROR'

export const DEVICES_TYPES_REQUEST = '@api/DEVICES_TYPES_REQUEST'
export const DEVICES_TYPES_SUCCESS = '@api/DEVICES_TYPES_SUCCESS'
export const DEVICES_TYPES_ERROR = '@api/DEVICES_TYPES_ERROR'

export const DEVICE_DETAIL_REQUEST = '@api/DEVICE_DETAIL_REQUEST'
export const DEVICE_DETAIL_SUCCESS = '@api/DEVICE_DETAIL_SUCCESS'
export const DEVICE_DETAIL_ERROR = '@api/DEVICE_DETAIL_ERROR'

export const DEVICES_REQUEST = '@api/DEVICES_REQUEST'
export const DEVICES_SUCCESS = '@api/DEVICES_SUCCESS'
export const DEVICES_ERROR = '@api/DEVICES_ERROR'

export const DEVICE_CLEAN_REQUEST = '@api/DEVICE_CLEAN_REQUEST'

export const AVAILABlE_SONIS_REQUEST = '@api/AVAILABLE_SONIS_REQUEST'
export const AVAILABLE_SONIS_SUCCESS = '@api/AVAILABLE_SONIS_SUCCESS'
export const AVAILABLE_SONIS_ERROR = '@api/AVAILABLE_SONIS_ERROR'

export const DEVICE_CREATE_REQUEST = '@api/DEVICE_CREATE_REQUEST'
export const DEVICE_CREATE_SUCCESS = '@api/DEVICE_CREATE_SUCCESS'
export const DEVICE_CREATE_ERROR = '@api/DEVICE_CREATE_ERROR'

export const DEVICE_UPDATE_REQUEST = '@api/DEVICE_UPDATE_REQUEST'
export const DEVICE_UPDATE_SUCCESS = '@api/DEVICE_UPDATE_SUCCESS'
export const DEVICE_UPDATE_ERROR = '@api/DEVICE_UPDATE_ERROR'

export const DEVICE_DELETE_REQUEST = '@api/DEVICE_DELETE_REQUEST'
export const DEVICE_DELETE_SUCCESS = '@api/DEVICE_DELETE_SUCCESS'
export const DEVICE_DELETE_ERROR = '@api/DEVICE_DELETE_ERROR'

export const VOLUME_UPDATE_REQUEST = '@api/VOLUME_UPDATE_REQUEST'
export const VOLUME_UPDATE_SUCCESS = '@api/VOLUME_UPDATE_SUCCESS'
export const VOLUME_UPDATE_ERROR = '@api/VOLUME_UPDATE_ERROR'

export const DEVICE_CLEAN_SUCCESS_REQUEST = '@api/DEVICE_CLEAN_SUCCESS_REQUEST'
export const DEVICE_CLEAN_ERROR_REQUEST = '@api/DEVICE_CLEAN_ERROR_REQUEST'
