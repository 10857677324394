import { Divider, List, MenuList } from '@mui/material'
import {
  BedroomChild,
  Dashboard,
  DevicesOther,
  Group,
  Handyman,
  CleaningServices,
  CreditCard,
  LocalHospital,
  PermContactCalendar,
  RoomService,
  SettingsApplications,
  SettingsRemote,
  SupervisorAccount,
  HomeWork,
} from '@mui/icons-material'
import { TTradFunc } from '../types'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import MenuItem from './menuItems/MenuListItem'

interface IProps {
  t: TTradFunc
}

const e2eprefix = 'navbar-'

export default function HipMenuBar({ t }: IProps) {
  const { hasPrivilege } = usePrivilege()

  return (
    <MenuList>
      <List>
        {hasPrivilege(Privileges.VIEW_DASHBOARD) && (
          <MenuItem
            title={t('roomsboard')}
            icon={<Dashboard />}
            to="/roomsboard"
            data-e2e={`${e2eprefix}roomsboard`}
          />
        )}
      </List>
      <Divider />
      <List>
        {hasPrivilege(Privileges.VIEW_DEVICE) && (
          <MenuItem
            title={t('rcu_devices')}
            icon={<SettingsRemote />}
            to="/rcus"
            data-e2e={`${e2eprefix}rcus`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_ROOMS) && (
          <MenuItem
            title={t('rooms')}
            icon={<BedroomChild />}
            to="/rooms"
            data-e2e={`${e2eprefix}rooms`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_DEVICE) && (
          <MenuItem title={t('soni_devices')} icon={<DevicesOther />} to="/devices" />
        )}
        {hasPrivilege(Privileges.VIEW_ZONES) && (
          <MenuItem title={t('zones')} icon={<HomeWork />} to="/groups" data-e2e="navbar-zones" />
        )}
        {hasPrivilege(Privileges.VIEW_PROFILES) && (
          <MenuItem
            title={t('profiles')}
            icon={<PermContactCalendar />}
            to="/profiles"
            data-e2e="navbar-profiles"
          />
        )}
        {hasPrivilege(Privileges.VIEW_HIP_USERS) && (
          <MenuItem
            title={t('nurses')}
            icon={<LocalHospital />}
            // icon={<NurseIcon style={{ width: 35, height: 35, fill: 'grey' }} />}
            to="/nurses"
            data-e2e={`${e2eprefix}nurses`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_HIP_USERS) && (
          <MenuItem
            title={t('maintenance')}
            icon={<Handyman />}
            to="/maintenance"
            // data-e2e={`${e2eprefix}maintenance`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_HIP_USERS) && (
          <MenuItem
            title={t('cleaning')}
            icon={<CleaningServices />}
            to="/cleaning"
            // data-e2e={`${e2eprefix}cleaning`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_HIP_USERS) && (
          <MenuItem
            title={t('admission')}
            icon={<SupervisorAccount />}
            to="/admission"
            // data-e2e={`${e2eprefix}admission`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_HIP_USERS) && (
          <MenuItem
            title={t('roomReady')}
            icon={<CreditCard />}
            to="/roomReady"
            // data-e2e={`${e2eprefix}roomReady`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_HIP_USERS) && (
          <MenuItem
            title={t('roomService')}
            icon={<RoomService />}
            to="/roomService"
            // data-e2e={`${e2eprefix}roomService`}
          />
        )}
        {hasPrivilege(Privileges.VIEW_USERS) && (
          <MenuItem title={t('user_title')} icon={<Group />} to="/users" data-e2e="navbar-users" />
        )}
        {hasPrivilege(Privileges.VIEW_SETTINGS) && (
          <MenuItem
            title={t('rcu_settings')}
            icon={<SettingsApplications />}
            to="/rcu_settings"
            data-e2e="navbar-rcu_settings"
          />
        )}
      </List>
    </MenuList>
  )
}
