import { useForm } from 'react-hook-form'
import SettingsIcon from '@mui/icons-material/Settings'
import { Done, Cancel } from '@mui/icons-material'
import { TextField, Button, Grid, CircularProgress } from '@mui/material'
import { CommonContent } from '../components/CommonContent'
import { useDispatch, useSelector } from 'react-redux'
import {
  settingsCreateRequest,
  settingsDetailsRequest,
  settingsUpdateRequest,
} from '../common/store/actions/settings/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Privileges } from '../enum'
import usePrivilege from '../hooks/usePrivilege'
import { useEffect } from 'react'
import { NewSetting, Setting } from '../types'
import { settingsStates } from '../common/store/selectors/settings'

interface FormData {
  key: string
  type: string
  value: string
}

interface IProps {
  scope?: string
}

const SettingsForm = ({ scope }: IProps) => {
  const { id } = useParams()
  const isEdit = id

  useEffect(() => {
    if (isEdit) {
      dispatch(settingsDetailsRequest(Number(id)))
    }
  }, [id, isEdit])

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hasPrivilege } = usePrivilege()

  const { setting, isLoading } = useSelector(settingsStates)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      key: isEdit ? setting?.key : '',
      type: 'string',
      value: isEdit ? setting?.value : '',
    },
  })

  useEffect(() => {
    if (isEdit && setting) {
      reset({
        key: setting.key,
        type: setting.type || 'string',
        value: setting.value,
      })
    }
  }, [setting, isEdit, reset])

  const onSubmit = (data: FormData) => {
    if (isEdit) {
      if (setting?.id) {
        const updateData: Setting = {
          id: setting.id,
          key: data.key ? data.key : setting.key,
          type: data.type || 'string',
          value: data.value ? data.value : setting.value,
          scope: setting.scope,
        }
        dispatch(settingsUpdateRequest(updateData))
        navigate(`/${setting.scope}_settings`)
      }
    } else {
      // Add new setting
      const addData: NewSetting = {
        key: data.key,
        type: data.type || 'string',
        value: data.value,
        scope,
      }
      dispatch(settingsCreateRequest(addData))
      navigate(`/${scope}_settings`)
    }
    reset({
      key: '',
      type: 'string',
      value: '',
    })
  }

  const handleCancel = () => {
    const route = isEdit ? setting.scope : scope
    navigate(`/${route}_settings`)
  }

  return (
    <CommonContent
      title={isEdit ? t('edit_setting') : t('add_setting')}
      titleIcon={<SettingsIcon style={{ color: 'white' }} />}
      data-e2e={isEdit ? 'setting-title-edit' : 'setting-title-add'}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('key', { required: true })}
                label="Key"
                fullWidth
                error={errors.key ? true : false}
                helperText={errors.key ? 'Key is required' : ''}
                data-e2e="key"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                {...register('type')}
                label="Type"
                fullWidth
                value="string"
                InputProps={{
                  readOnly: true,
                }}
                helperText={t('read_only')}
                data-e2e="type"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('value', { required: true })}
                label="Value"
                fullWidth
                error={errors.value ? true : false}
                helperText={errors.value ? 'Value is required' : ''}
                data-e2e="value"
              />
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" spacing={2} mt={3}>
              <Grid item>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleCancel}
                  startIcon={<Cancel />}
                >
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item>
                {hasPrivilege(Privileges.ADD_SETTINGS) && (
                  <Button
                    data-e2e="add-settings"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(onSubmit)()}
                    startIcon={<Done />}
                  >
                    {isEdit ? 'save' : 'add'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </CommonContent>
  )
}

export default SettingsForm
