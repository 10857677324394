import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { CommonContent } from '../../components/CommonContent'
import { CircularProgress, Typography } from '@mui/material'
import { BedroomChild } from '@mui/icons-material'
import {
  roomsRequest,
  roomCleanErrorRequest,
  roomCleanSuccessRequest,
} from '../actions/rooms/actions'
import { roomsStates } from '../store/selectors/rooms'
import RoomsTables from './RoomsTables'
import RoomForm from './RoomForm'
import { useSnackbar } from 'notistack'
import Paginate from '../components/Paginate'

const RoomsView = () => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { rooms, isLoading, success, error } = useSelector(roomsStates)
  const [selectedPage, setSelectedPage] = useState('1')

  useEffect(() => {
    dispatch(roomsRequest({ limit: '4', page: selectedPage }))
  }, [selectedPage])

  useEffect(() => {
    if (rooms.items.length > 0) {
      console.log('rendering', rooms)
    }
  }, [rooms])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(roomCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(roomCleanErrorRequest())
    }
  }, [success, error])

  return (
    <CommonContent title={t('rooms')} titleIcon={<BedroomChild style={{ color: 'white' }} />}>
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          {rooms.items.length ? (
            <RoomsTables rooms={rooms.items} />
          ) : (
            <Typography variant="h6" color="text.secondary" align="center" p={50}>
              {t('no_items')}
            </Typography>
          )}
          {!rooms.items.length && selectedPage == '1' ? null : (
            <Paginate
              pages={rooms.pages}
              currentPage={rooms.page}
              setSelectedPage={setSelectedPage}
            />
          )}
          {hasPrivilege(Privileges.ADD_ROOMS) && <RoomForm isEdit={false} />}
        </>
      )}
    </CommonContent>
  )
}

export default RoomsView
