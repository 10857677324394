import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import { PeopleAlt, PersonAdd, Delete, Edit } from '@mui/icons-material'
import { CommonContent } from '../components/CommonContent'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { UserType } from '../types'
import {
  userDeleteRequest,
  usersCleanErrorRequest,
  usersCleanSuccessRequest,
  usersRequest,
} from '../common/store/actions/users/actions'
import { usersState } from '../common/store/selectors/users'

export default function Users() {
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { users, success, error } = useSelector(usersState)

  const [open, setOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState<Partial<UserType>>({})

  useEffect(() => {
    dispatch(usersRequest())
  }, [dispatch])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(usersCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(usersCleanErrorRequest())
    }
  }, [success, error])

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = (user: UserType) => {
    setUserToDelete(user)
    setOpen(true)
  }

  const handleConfirmDelete = () => {
    dispatch(userDeleteRequest(userToDelete.id))
    setOpen(false)
  }

  return (
    <CommonContent title={t('user_title')} titleIcon={<PeopleAlt style={{ color: 'white' }} />}>
      {hasPrivilege(Privileges.ADD_USERS) && (
        <Box mb={4}>
          <Button
            data-e2e="users-btn-add"
            component={Link}
            to="/users/add"
            variant="contained"
            color="success"
            startIcon={<PersonAdd />}
          >
            {t('user_add')}
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Divider />
        <Table>
          <TableHead style={{ textAlign: 'center' }}>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">{t('username')}</TableCell>
              <TableCell align="center">{t('name')}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} data-e2e={'users-user-' + String(user.id)}>
                <TableCell
                  component="th"
                  scope="user"
                  align="center"
                  data-e2e={'users-user-cell-id'}
                >
                  {user.id}
                </TableCell>
                <TableCell align="center" data-e2e={'users-user-cell-username'}>
                  {user.username}
                </TableCell>
                <TableCell align="center" data-e2e={'users-user-cell-name'}>
                  {user.name}
                </TableCell>
                <TableCell align="center" data-e2e={'users-user-cell-actions'}>
                  <Grid>
                    {hasPrivilege(Privileges.EDIT_USERS) && user.username !== 'admin' && (
                      <Button
                        component={Link}
                        to={'/users/' + user.id}
                        data-e2e={'users-user-cell-actions-edit'}
                        color="primary"
                      >
                        <Edit />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    {hasPrivilege(Privileges.DELETE_USERS) && user.username !== 'admin' && (
                      <Button
                        variant="text"
                        onClick={() => handleClick(user)}
                        data-e2e={'users-user-cell-actions-delete'}
                        color="error"
                      >
                        <Delete />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="users-add-dlg-delete"
      >
        <DialogTitle id="responsive-dialog-title">{t('user_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('user_delete_ask')}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleConfirmDelete()}
            variant="outlined"
            color="error"
            data-e2e="users-add-dlg-delete-btn-delete"
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="users-add-dlg-delete-btn-cancel"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </CommonContent>
  )
}
