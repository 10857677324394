import { useState } from 'react'
import {
  Checkbox,
  Grid,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IpDevice, Profile, Tasks } from '../types'
import { Soni } from '../common/api/types'
import { Room } from '../hip/types'

interface IProps {
  items: (Tasks | IpDevice | Room | Profile | Soni)[]
  selectedItems: number[]
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>
  type: 'tasks' | 'devices' | 'rooms' | 'profiles' | 'sonis'
}

const isHIP = process.env?.REACT_APP_PRODUCT_NAME === 'HELP_IP'
const fixedProfiles = [2, 3, 4, 5, 6, 7, 8]

const CheckboxSelector = ({ items, selectedItems, setSelectedItems, type }: IProps) => {
  const { t } = useTranslation()
  const [selectAll, setSelectAll] = useState(false)
  const isSelected = (itemId: number) => selectedItems.includes(itemId)

  const toggleSelection = (itemId: number) => {
    // Solo para perfiles y productos HIP se bloquea la deselección de algunos ítems
    if (isHIP && type === 'profiles' && fixedProfiles.includes(itemId)) {
      if (!isSelected(itemId)) {
        setSelectedItems([...selectedItems, itemId])
      }
    } else {
      const updatedSelected = isSelected(itemId)
        ? selectedItems.filter((item) => item !== itemId)
        : [...selectedItems, itemId]
      setSelectedItems(updatedSelected)
    }
  }

  const handleSelectAll = () => {
    const updatedSelected = selectAll ? [] : items.map((item) => item.id)
    setSelectedItems(updatedSelected)
    setSelectAll(!selectAll)
  }

  const getItemLabel = (item: Tasks | IpDevice | Room | Profile | Soni) => {
    switch (type) {
      case 'devices':
        return (item as IpDevice).description || (item as Soni).description
      case 'profiles':
        return (item as Profile).name
      case 'rooms':
        return (item as Room).name
          ? (item as Room).name
          : `Room: ${(item as Room).roomNo} Floor: ${(item as Room).floor}`
      case 'tasks':
        return (item as Tasks).name
      default:
        return (item as any).name || (item as any).description
    }
  }

  const CheckedItems = () => (
    <Paper sx={{ width: 300, height: 230, overflow: 'auto', padding: '25px' }}>
      <FormGroup>
        {items.map((item) =>
          isSelected(item.id) ? (
            <FormControlLabel
              data-e2e="checkbox-selected-item"
              key={item.id}
              control={
                <Checkbox
                  data-e2e={`checkbox-selected-item-${item.id}`}
                  checked={true}
                  onChange={() => toggleSelection(item.id)}
                  disabled={isHIP && type === 'profiles' && fixedProfiles.includes(item.id)}
                />
              }
              label={getItemLabel(item)}
            />
          ) : null,
        )}
      </FormGroup>
    </Paper>
  )

  const UncheckedItems = () => (
    <Paper sx={{ width: 300, height: 230, overflow: 'auto', padding: '25px' }}>
      <FormGroup>
        {items.length ? (
          items.map((item) =>
            !isSelected(item.id) ? (
              <FormControlLabel
                data-e2e="checkbox-item"
                key={item.id}
                control={
                  <Checkbox
                    data-e2e={`checkbox-item-${item.id}`}
                    checked={false}
                    onChange={() => toggleSelection(item.id)}
                  />
                }
                label={getItemLabel(item)}
              />
            ) : null,
          )
        ) : (
          <Typography color="text.secondary" data-e2e="rooms-text-avRcus">
            {t('available_devices_not')}
          </Typography>
        )}
      </FormGroup>
    </Paper>
  )

  return (
    <Grid container spacing={2} alignItems="center">
      <FormLabel component="legend">{t(`available_${type}`)}</FormLabel>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
              label={t('select_all')}
            />
          </FormGroup>
        </Grid>
        <FormLabel component="legend">{t('selected')}</FormLabel>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <UncheckedItems />
        </Grid>
        <Grid item xs={4}>
          <CheckedItems />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CheckboxSelector
