import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Button, Grid, FormControl, TextField } from '@mui/material'
import { BedroomChild, AddCircle, Done } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import { roomCreateRequest, roomUpdateRequest } from '../actions/rooms/actions'
import { availableRcusRequest } from '../actions/rcus/actions'
import { rcusStates } from '../store/selectors/rcus'
import { Room, UpdatedRoomdData } from '../types'
import { roomsStates } from '../store/selectors/rooms'
import CheckboxSelectorAvailable from '../../components/CheckBoxSelectorAvailable'

export type RoomFormValues = {
  orderId: number
  roomNo: string
  floor: string
  name: string
}

interface RoomFormProps {
  isEdit: boolean
  room?: Room
}

const RoomForm = ({ isEdit, room }: RoomFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rooms } = useSelector(roomsStates)
  const nextOrderId = rooms.total + 1
  const { availablesRcus } = useSelector(rcusStates)
  const [selectedRcus, setSelectedRcus] = useState<number[]>([])
  const { control, handleSubmit, reset, getValues } = useForm<RoomFormValues>({
    defaultValues: {
      orderId: isEdit ? room.orderId : nextOrderId,
      roomNo: isEdit ? room.roomNo : '',
      floor: isEdit ? room.floor : '',
      name: isEdit ? room.name : '',
    },
  })

  useEffect(() => {
    dispatch(availableRcusRequest())
    if (isEdit && room) {
      const currentRcus = room.rcus.map((rcu) => rcu.id)
      setSelectedRcus(currentRcus)
      reset({
        name: room.name,
        orderId: room.orderId,
        roomNo: room.roomNo,
        floor: room.floor,
      })
    }
  }, [isEdit, room])

  const onSubmit = handleSubmit((data: RoomFormValues) => {
    if (data) {
      const { name, orderId, roomNo, floor } = data
      const updatedData: UpdatedRoomdData = {
        id: isEdit ? room?.id : undefined,
        orderId,
        name,
        roomNo,
        floor,
        rcus: selectedRcus,
      }
      if (isEdit) {
        dispatch(roomUpdateRequest(updatedData))
      } else {
        console.log('Soy el room', updatedData)
        dispatch(roomCreateRequest(updatedData))
      }
      setSelectedRcus([])
      reset({
        name: '',
        orderId: undefined,
        roomNo: '',
        floor: '',
      })
    }
  })

  return (
    <CommonContent
      title={isEdit ? t('edit_room') : t('Add a room')}
      titleIcon={<BedroomChild style={{ color: 'white' }} />}
    >
      <form>
        <Grid container direction="row" alignItems="center" spacing={5} m={5}>
          <Grid item xs={2} style={{ minHeight: '100px' }}>
            <Controller
              name="orderId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const orderId = getValues('orderId').toString()
                  const regex = /^[0-9]+$/
                  if (!regex.test(orderId)) return t('not_valid_number')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="rooms-fld-orderId"
                  helperText={error ? error.message : t('default_value')}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEdit ? t('update_orderId') : t('orderId')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} style={{ minHeight: '100px' }}>
            <Controller
              name="roomNo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const roomNo = getValues('roomNo').toString()
                  const regex = /^[0-9]+$/
                  if (!regex.test(roomNo)) return t('not_valid_number')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="rooms-fld-roomNo"
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEdit ? t('update_roomNo') : t('roomNo')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} style={{ minHeight: '100px' }}>
            <Controller
              name="floor"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const floor = getValues('floor').toString()
                  const regex = /^(\d{1,2})?$/
                  if (!regex.test(floor)) return t('not_valid_number')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="rooms-fld-floor"
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEdit ? t('update_floor') : t('floor')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} style={{ minHeight: '100px' }}>
            <Controller
              name="name"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: t('max_length', { max: 20 }),
                },
                validate: () => {
                  const name = getValues('name')
                  if (name.trim() !== name) return t('check_empty')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="rooms-fld-name"
                  helperText={error ? error.message : t('optional')}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEdit ? t('update_name') : t('name')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl
              component="fieldset"
              fullWidth
              size="medium"
              variant="outlined"
              style={{ margin: '20px' }}
            >
              <CheckboxSelectorAvailable
                items={availablesRcus}
                selectedItems={selectedRcus}
                setSelectedItems={setSelectedRcus}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" justifyContent="flex-end" spacing={2} mt={3}>
        <Grid item>
          <Button
            data-e2e={isEdit ? 'rooms-btn-save' : 'rooms-btn-add'}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            startIcon={<AddCircle />}
            disabled={!selectedRcus.length}
          >
            {isEdit ? t('save') : t('add')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-e2e="rooms-btn-done"
            variant="contained"
            color="success"
            type="submit"
            onClick={isEdit ? () => navigate('/rooms') : () => navigate('/roomsboard')}
            startIcon={<Done />}
          >
            {t('done')}
          </Button>
        </Grid>
      </Grid>
    </CommonContent>
  )
}

export default RoomForm
