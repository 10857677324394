import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, TextField } from '@mui/material'
import { AddCircle, Cancel } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import { NewUser, NewUserData, Profile } from '../../types'
import { HipUserFormValues, ProfileMapping } from '../types'
import { hip_userCreateRequest, hip_userUpdateRequest } from '../actions/hipUsers/actions'
import { topicGenerator, multicast_acrt, ipMulticast } from '../../Utils'
import { userIcon } from './utilsHipUser'

import axios from 'axios'
import { IUserProfile } from '../../common/api/types'
import CheckboxSelector from '../../components/CheckboxSelector'

interface HipUserFormProps {
  userType: string
  initialValues?: HipUserFormValues
  setOpen: (open: boolean) => void
}

const HipUserForm = ({ userType, initialValues, setOpen }: HipUserFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const param = useParams()
  const { id } = param
  const { handleSubmit, reset, control, getValues } = useForm<HipUserFormValues>()
  const isEditForm = !!initialValues
  const [profiles, setProfiles] = useState<Profile[]>()
  const profileMapping: ProfileMapping = {
    nurses: [2],
    maintenance: [4],
    cleaning: [5],
    roomReady: [6],
    admission: [7],
    roomService: [8],
  }
  const selected_profile = profileMapping[userType]
  const [selectedItems, setSelectedItems] = useState<number[]>(selected_profile)

  useEffect(() => {
    // if (!profiles.length) {
    //   dispatch(profilesRequest())
    // }
    const fetchData = async () => {
      try {
        const prof: any = await axios.get<Profile[]>(`/v0/api/profile`)
        let profiles = prof.data
        profiles = profiles.filter(
          (profile: Profile) =>
            ![1, 2, 3, 4, 5, 6, 7, 8].includes(profile.id) || selected_profile.includes(profile.id),
        )
        setProfiles(profiles)
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
    if (isEditForm) {
      const fetchData = async () => {
        try {
          const prof = await axios.get<IUserProfile>(`/v0/api/user_profile/${id}`)
          const user_profiles = prof.data.profiles.map((profile) => profile.profile_id)
          setSelectedItems(user_profiles)
        } catch (e) {
          console.log(e)
        }
      }
      fetchData()
    }
  }, [id])

  const onSubmit = handleSubmit((data: HipUserFormValues) => {
    if (data) {
      const { username, password, name, key } = data
      const newUser: NewUser = {
        username,
        password,
        name,
        mqtt_topic: topicGenerator(),
        multicast_ip: `224.0.0.${ipMulticast}`,
        multicast_port: multicast_acrt,
        active: true,
      }
      const newData: NewUserData = {
        data: newUser,
        key,
        profiles: selectedItems,
      }
      if (isEditForm) {
        newData.id = id
        dispatch(hip_userUpdateRequest(newData))
      } else {
        dispatch(hip_userCreateRequest(newData))
      }
    }
    reset({
      username: '',
      password: '',
      name: '',
      key: '',
    })
    setSelectedItems([])
  })

  const handleClose = () => {
    if (isEditForm) navigate(`/${userType}`)
    else setOpen(false)
  }

  return (
    <CommonContent
      title={isEditForm ? t(`edit_${userType}`) : t(`add_${userType}`)}
      titleIcon={userIcon(userType)}
      data-e2e={isEditForm ? 'nrs-title-edit' : 'nrs-title-add'}
    >
      <Box width={'75%'} p={'35px'}>
        <form>
          <Controller
            name="username"
            control={control}
            defaultValue={isEditForm ? initialValues.username : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
              validate: () => {
                const name = getValues('username')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="nrs-user-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={isEditForm ? t('update_username') : t('username')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name={'password'}
            control={control}
            defaultValue={''}
            rules={{
              required: !isEditForm
                ? {
                    value: true,
                    message: t('required'),
                  }
                : undefined,
              validate: () => {
                const name = getValues('password')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="nrs-pass-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={isEditForm ? t('update_password') : t('password')}
                variant="outlined"
              />
            )}
          />
          <Controller
            name={'name'}
            control={control}
            defaultValue={isEditForm ? initialValues.name : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="nrs-name-fld"
                style={{ paddingBottom: 20 }}
                fullWidth
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                label={isEditForm ? t('update_name') : t('name')}
                variant="outlined"
              />
            )}
          />
          {!isEditForm && userType !== 'admission' && (
            <Controller
              name={'key'}
              control={control}
              defaultValue={''}
              rules={{
                validate: () => {
                  const key = getValues('key')
                  if (key.trim() !== key) return t('check_empty')
                  const regex = /^(\d{7,8})?$/
                  if (!regex.test(key)) return t('not_key')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="nrs-key-fld"
                  style={{ paddingBottom: 20 }}
                  fullWidth
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  label={t('add_card')}
                  variant="outlined"
                />
              )}
            />
          )}
        </form>
      </Box>
      {profiles && (
        <Box mt={4} border={'1px solid lightgrey'} borderRadius="5px" m={4} p={5}>
          <CheckboxSelector
            items={profiles}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            type="profiles"
          />
        </Box>
      )}
      <Grid container direction="row" justifyContent="flex-end" spacing={2} paddingRight={'35px'}>
        <Grid item>
          <Button
            data-e2e="nrs-btn-cancel"
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            startIcon={<Cancel />}
          >
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-e2e={isEditForm ? 'nrs-btn-save' : 'nrs-btn-add'}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            startIcon={<AddCircle />}
          >
            {isEditForm ? t('save') : t('add')}
          </Button>
        </Grid>
      </Grid>
    </CommonContent>
  )
}

export default HipUserForm
