import { ButtonAtr, MessageNote, HipIconType, FeedbackAtr } from '../types'

export const getButtonAttributesAndMessage = (feedback: FeedbackAtr) => {
  const result: ButtonAtr = {
    image: 'ERROR',
    fill: '#354652',
  }

  const messageResult: MessageNote = {
    message: null,
  }

  type AttributeMapping = {
    [key: string]: {
      fill: string
      image: HipIconType
      message?: string
    }
  }

  const attributeMapping: AttributeMapping = {
    online: { fill: '#979797', image: 'OK' },
    offline: { fill: '#354652', image: 'ERROR' },
    ReCard: { fill: '#43aaf3', image: 'ROOM_READY', message: 'Room ready' },
    BedMd: { fill: '#f9b03d', image: 'CLEANING_REQ', message: 'Cleaning request' },
    BedRS: { fill: '#f9b03d', image: 'ROOM_SERVICE_REQ', message: 'Room service request' },
    BedCl: { fill: '#f9b03d', image: 'BED_CALL', message: 'Bed call' },
    BedNA: { fill: '#5c2582', image: 'IGNORED', message: 'Bed call unattended' },
    BathCl: { fill: '#f9b03d', image: 'BATHROOM_CALL', message: 'Bathroom call' },
    BathNA: { fill: '#5c2582', image: 'BATHROOM_IGNORED', message: 'Bathroom call unattended' },
    CBlue: { fill: '#df007c', image: 'CODE_BLUE', message: 'CODE BLUE' },
    CBlNA: { fill: '#5c2582', image: 'CODE_BLUE_NA', message: 'CODE BLUE UNATTENDED' },
    ClIn: { fill: '#f05a25', image: 'MESSAGE_SENT', message: 'Calling room' },
    ClEst: { fill: '#f05a25', image: 'MESSAGE_SENT', message: 'Call established' },
    BathEst: { fill: '#f05a25', image: 'BATH_MESSAGE_SENT', message: 'Bathroom call established' },
    NBt: { fill: '#43aaf3', image: 'NURSE', message: 'Nurse at room' },
    NuCard: { fill: '#43aaf3', image: 'NURSE', message: 'Nurse at room' },
    BAssign: { fill: '#43aaf3', image: 'ASSIGNED', message: 'Nurse assigned' },
    MaCard: { fill: '#43aaf3', image: 'MAINTENANCE', message: 'Room under maintenance' },
    ClCard: { fill: '#43aaf3', image: 'CLEANING', message: 'Room under cleaning' },
    SeCard: { fill: '#43aaf3', image: 'ROOM_SERVICE', message: 'Room service' },
    EoS: { fill: '#979797', image: 'OK', message: 'End of service' },
    EoSC: { fill: '#979797', image: 'OK', message: 'End of service' },
    EoSRR: { fill: '#979797', image: 'OK', message: 'End of service' },
    EoSX: { fill: '#979797', image: 'OK', message: 'Restored to idle' },
  }

  const roomNew = feedback?.new
  if (roomNew && attributeMapping[roomNew]) {
    const { fill, image, message } = attributeMapping[roomNew]
    result.fill = fill
    result.image = image
    messageResult.message = message
  }

  return { attributes: result, message: messageResult }
}
