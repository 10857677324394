import { Box } from '@mui/material'
import ButtonPanel from './ButtonPanel'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { useDispatch, useSelector } from 'react-redux'
import { mqttRequest } from '../../common/store/actions/mqtt/actions'
import { getMqttState } from '../../common/store/selectors/mqtt'
import { resetDevices } from '../../common/api/devices/devices'

const productHelpIp = process.env.REACT_APP_PRODUCT_NAME === 'HELP_IP'

const reset = process.env?.REACT_APP_RESET_COMMAND

const ResetSystemButton = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(getMqttState)

  const onClickHandle = () => {
    if (productHelpIp) {
      dispatch(mqttRequest({ topic: 'rcu/jcmd/ALL', payload: '{"mod":"disp", "cmd":"rst"}' }))
      dispatch(mqttRequest({ topic: 'soni/jcmd/ALL', payload: '{"mod":"disp", "cmd":"rst"}' }))
    } else {
      dispatch(mqttRequest({ topic: 'spkr/jcmd/ALL', payload: reset }))
    }
    resetDevices()
  }

  return (
    <ButtonPanel
      variant="contained"
      widthAndHeight={72}
      color="primary"
      onClick={onClickHandle}
      loading={isLoading}
      tooltip="Reset all devices"
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Box m={4} width="100%" height="100%">
          <RestartAltIcon
            sx={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </ButtonPanel>
  )
}

export default ResetSystemButton
