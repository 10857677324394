import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Cancel, Done, SpeakerGroup } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material'
import { CommonContent } from '../components/CommonContent'
import CheckboxSelector from '../components/CheckboxSelector'
import { devicesState } from '../common/store/selectors/devices'
import { availableSonisRequest, ipdevicesRequest } from '../common/store/actions/devices/actions'
import { groupsStates } from '../common/store/selectors/groups'
import { NewZone } from '../common/api/types'
import {
  groupCreateRequest,
  groupRequest,
  groupUpdateRequest,
} from '../common/store/actions/groups/actions'
import { roomsStates } from '../hip/store/selectors/rooms'
import { availableRoomsRequest } from '../hip/actions/rooms/actions'
import { getUserInfo } from '../common/store/selectors/user'

const isHIP = process.env?.REACT_APP_PRODUCT_NAME == 'HELP_IP'

export type ZoneFormValues = {
  name: string
  description: string
  ipMulticast?: string
  portMulticast?: number
}

export const ZoneForm = () => {
  const { paging_mode } = useSelector(getUserInfo)
  const isMulticast = paging_mode == 'MULTICAST'
  const { id } = useParams()
  const isEdit = id !== 'add'

  useEffect(() => {
    if (!isHIP) {
      dispatch(ipdevicesRequest({ limit: '500', page: '1' }))
    }
    if (isHIP) {
      dispatch(availableRoomsRequest())
      dispatch(availableSonisRequest())
    }
    if (isEdit) {
      dispatch(groupRequest(Number(id)))
    }
  }, [id])

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { ipDevices, availableSonis } = useSelector(devicesState)
  const { availablesRooms } = useSelector(roomsStates)
  const { group, isLoading } = useSelector(groupsStates)
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [selectedSoni, setselectedSoni] = useState<number[]>([])
  const [isMusic, setIsMusic] = useState<boolean>(false)
  const { control, handleSubmit, reset, getValues } = useForm<ZoneFormValues>()

  useEffect(() => {
    if (isEdit && group) {
      reset({
        name: group.name,
        description: group.description,
        ipMulticast: group.ipMulticast || '',
        portMulticast: group.portMulticast || undefined,
      })
      const items = isHIP
        ? group.rooms.map((room) => room.id)
        : group.devices.map((device) => device.id)
      setSelectedItems(items)
      if (group.soni) setselectedSoni([group.soni.id])
      if (group.isMusic && isMulticast) setIsMusic(true)
    }
  }, [group, isEdit, reset])

  const groupItems = isHIP ? group?.rooms : group?.devices

  const combinedItems =
    isEdit && isHIP
      ? [...availablesRooms, ...(groupItems || [])]
      : isHIP
      ? availablesRooms
      : ipDevices.items

  const combinedSonis =
    isEdit && isHIP && group?.soni ? [...availableSonis, group?.soni] : availableSonis

  const handleCheckboxChange = (e: any) => {
    const { checked } = e.target
    setIsMusic(checked)
  }

  const cleanStates = () => {
    navigate('/groups')
    reset({
      name: '',
      description: '',
      ipMulticast: '',
      portMulticast: null,
    })
    setSelectedItems([])
    setIsMusic(false)
  }

  const onSubmit = handleSubmit((data: ZoneFormValues) => {
    if (data) {
      const newZone: NewZone = {
        name: data.name,
        description: data.description,
        ...(isHIP ? { rooms: selectedItems } : { devices: selectedItems }),
        isMusic: isMusic,
      }

      if (isMusic && isMulticast) {
        newZone.ipMulticast = data.ipMulticast
        newZone.portMulticast = data.portMulticast
      }

      if (isHIP && selectedSoni) {
        newZone.soni_id = selectedSoni[0] //Un Soni por zona
      }

      if (isEdit) {
        const newData = {
          body: newZone,
          id: group.id,
        }
        dispatch(groupUpdateRequest(newData))
      } else {
        dispatch(groupCreateRequest(newZone))
      }
      cleanStates()
    }
  })

  return (
    <CommonContent
      title={isEdit ? t('edit_zone') : t('zones')}
      titleIcon={<SpeakerGroup style={{ color: 'white' }} />}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <form>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
              validate: () => {
                const name = getValues('name')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
              <TextField
                data-e2e="zones-fld-name"
                label={isEdit ? t('update_name') : t('name')}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : null}
                error={!!error}
                style={{ paddingBottom: 20 }}
                size="medium"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
            }}
            render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
              <TextField
                data-e2e="zones-fld-description"
                label={isEdit ? t('update_desc') : t('desc')}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : null}
                error={!!error}
                style={{ paddingBottom: 20 }}
                fullWidth
                size="small"
                variant="outlined"
              />
            )}
          />
          {isMulticast && (
            <FormControlLabel
              style={{ marginLeft: 20 }}
              control={<Checkbox checked={isMusic} onChange={handleCheckboxChange} />}
              label={t('is_music')}
              data-e2e="checkbox-isMusic"
            />
          )}
          {isMusic && (
            <>
              <Controller
                name="ipMulticast"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    data-e2e="zones-fld-ipMulticast"
                    label={isEdit ? t('update_ipMulticast') : t('ipMulticast')}
                    value={value}
                    onChange={onChange}
                    style={{ paddingBottom: 20 }}
                    fullWidth
                    placeholder="224.0.0.10"
                    helperText={error ? error.message : null}
                    error={!!error}
                    size="small"
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="portMulticast"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    data-e2e="zones-fld-portMulticast"
                    label={isEdit ? t('update_portMulticast') : t('portMulticast')}
                    value={value}
                    onChange={onChange}
                    style={{ paddingBottom: 20 }}
                    fullWidth
                    placeholder="52100"
                    helperText={error ? error.message : null}
                    error={!!error}
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </>
          )}

          <Box mt={4} border={'1px solid lightgrey'} borderRadius="5px" m={4} p={5}>
            <CheckboxSelector
              items={combinedItems}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              type={isHIP ? 'rooms' : 'devices'}
            />
          </Box>

          {isHIP && (
            <Box mt={4} border={'1px solid lightgrey'} borderRadius="5px" m={4} p={5}>
              <CheckboxSelector
                items={combinedSonis}
                selectedItems={selectedSoni}
                setSelectedItems={setselectedSoni}
                type="sonis"
              />
            </Box>
          )}

          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={() => {
                  cleanStates()
                }}
                variant="outlined"
                color="secondary"
                startIcon={<Cancel />}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedItems.length}
                onClick={onSubmit}
                startIcon={<Done />}
                data-e2e="zones-btn-add"
              >
                {isEdit ? t('save') : t('add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </CommonContent>
  )
}
