import { createReducer } from '@reduxjs/toolkit'
import {
  cleanLoginState,
  loginError,
  loginRequest,
  loginSuccess,
  userCleanErrorRequest,
  userCleanSuccessRequest,
  userInfoError,
  userInfoRequest,
  userInfoSuccess,
} from '../actions/user/actions'

interface UserInfoState {
  loged: boolean
  username: string | null
  id: number | null
  active: boolean | null
  name: string | null
  isLoading: boolean
  multicast_ip?: string
  multicast_port?: number
  mqtt_topic?: string
  success: string
  error: string
  paging_mode?: 'MQTT' | 'MULTICAST'
  local_installation?: 'Y' | 'N'
}

export const initialState = (): UserInfoState => {
  console.log('LoginInfo init state')

  const auth = JSON.parse(localStorage.getItem('auth') || 'null')

  return {
    loged: false,
    username: auth?.username || null,
    id: auth?.id || null,
    active: auth?.active || null,
    name: auth?.name || null,
    isLoading: false,
    success: '',
    error: '',
    multicast_ip: auth?.multicast_ip || undefined,
    multicast_port: auth?.multicast_port || undefined,
    mqtt_topic: auth?.mqtt_topic || undefined,
    paging_mode: auth?.paging_mode || undefined,
    local_installation: auth?.local_installation || undefined,
  }
}

export const userInfoReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(loginRequest, (state) => {
      console.log('loginRequest')
      state.loged = false
      state.isLoading = true
    })
    .addCase(loginSuccess, (state) => {
      state.loged = true
      state.success = 'login_success'
      state.isLoading = true
    })
    .addCase(loginError, (state, { payload }) => {
      console.log('loginError', payload)
      state.loged = false
      state.error = payload.message
      state.isLoading = true
    })
    .addCase(userInfoRequest, (state) => {
      console.log('userInfoRequest')
      state.isLoading = true
    })
    .addCase(userInfoSuccess, (state, { payload }) => {
      console.log('userInfoSuccess')
      state.username = payload.username
      state.name = payload.name
      state.active = payload.active
      state.id = payload.user_id
      state.isLoading = false
      state.mqtt_topic = payload.mqtt_topic
      state.multicast_ip = payload.multicast_ip
      state.multicast_port = payload.multicast_port
      state.paging_mode = payload.paging_mode
      state.local_installation = payload.local_installation
      localStorage.setItem('auth', JSON.stringify({ ...payload }))
    })
    .addCase(userInfoError, (state, { payload }) => {
      console.error('userReducer - userInfoError', payload)
      state.error = payload
      state.username = null
      state.id = null
      state.active = null
      state.name = null
      state.isLoading = false
      state.mqtt_topic = undefined
      state.multicast_ip = undefined
      state.multicast_port = undefined
      state.error = payload.message
    })
    .addCase(cleanLoginState, (state) => {
      console.log('cleanLoginState')
      state.loged = false
      state.success = 'log_out'
      state.username = null
      state.id = null
      state.active = null
      state.name = null
      state.isLoading = false
      state.mqtt_topic = undefined
      state.multicast_ip = undefined
      state.multicast_port = undefined
      localStorage.removeItem('auth')
      document.cookie = 'username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC'
    })
    .addCase(userCleanErrorRequest, (state) => {
      state.error = ''
    })
    .addCase(userCleanSuccessRequest, (state) => {
      state.success = ''
    })
})
