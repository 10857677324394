import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Rcu } from '../hip/types'

interface IProps {
  items: Rcu[]
  selectedItems: number[]
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>
}

const CheckboxSelectorAvailable = ({ items, selectedItems, setSelectedItems }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography color="text.secondary" data-e2e="rooms-text-avRcus">
        {items.length ? t('available_devices') : t('available_devices_not')}
      </Typography>

      <Box
        sx={{
          maxHeight: '200px',
          overflowY: 'auto',
          mt: 2,
          border: '1px solid lightgrey',
          borderRadius: '5px',
          p: 2,
        }}
      >
        {items.map((device) => (
          <Box key={device.id} mt={2} border={'1px solid lightgrey'} borderRadius="5px" p={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedItems.includes(device.id)}
                  onChange={(event) => {
                    const isChecked = event.target.checked
                    setSelectedItems((prevSelected) => {
                      if (isChecked) {
                        return [...prevSelected, device.id]
                      } else {
                        return prevSelected.filter((id) => id !== device.id)
                      }
                    })
                  }}
                  name={`device_${device.id}`}
                  color="primary"
                />
              }
              label={device.description}
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default CheckboxSelectorAvailable
