import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { availableSonisSuccess, availableSonisError } from '../../actions/devices/actions'
import { getAvailableSonis } from '../../../api/devices/soniAvailable'
import { Soni } from '../../../api/types'

export function* sagaGetAvailableSonis() {
  try {
    const response: Soni[] = yield call(getAvailableSonis)
    yield put(availableSonisSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(availableSonisError(error.response.data as unknown))
    } else {
      yield put(availableSonisError(error))
      throw error
    }
  }
}
