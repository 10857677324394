import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Grid, TextField } from '@mui/material'
import { AddCircle, Cancel, Key } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import { User_Key, KeyFormValues } from '../types'
import { keyCreateRequest, keyUpdateRequest } from '../actions/hipUsers/actions'
import { useEffect } from 'react'

interface HipUserKeyFormProps {
  hipUserInfo?: User_Key
  cleanAddKey: () => void
  addKey: { add: boolean; id: number }
}

const UsersKeyForm = ({ hipUserInfo, cleanAddKey }: HipUserKeyFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { handleSubmit, reset, control, getValues } = useForm<KeyFormValues>()

  const isEditForm = hipUserInfo.access_key ? true : false
  const keyId = hipUserInfo.access_key?.id

  useEffect(() => {
    console.log(hipUserInfo)
  }, [hipUserInfo])

  const onSubmit = handleSubmit((data: KeyFormValues) => {
    if (data) {
      const updatedData: KeyFormValues = {
        user_id: hipUserInfo.id,
        key: data.key,
      }
      if (isEditForm) {
        dispatch(keyUpdateRequest({ updatedData, keyId }))
        cleanAddKey()
      } else {
        dispatch(keyCreateRequest(updatedData))
        cleanAddKey()
      }
    }
    reset({
      key: null,
    })
  })

  return (
    <CommonContent
      title={isEditForm ? t('edit_card') : t('add_card')}
      titleIcon={<Key style={{ color: 'white' }} />}
      data-e2e={isEditForm ? 'nrs-key-edit' : 'nrs-key-add'}
    >
      <form>
        <Grid container direction="row" alignItems="center" spacing={3} m={6}>
          <Grid item xs={5}>
            <Controller
              name={'name'}
              control={control}
              defaultValue={hipUserInfo.name}
              render={() => (
                <TextField
                  size="small"
                  value={hipUserInfo.name}
                  fullWidth
                  label={t('name')}
                  variant="outlined"
                  disabled={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name={'key'}
              control={control}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: t('required'),
                },
                validate: () => {
                  const key = getValues('key')
                  if (key.trim() !== key) return t('check_empty')
                  const regex = /^\d{7,8}$/
                  if (!regex.test(key)) return t('not_key')
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  data-e2e="key-fld"
                  helperText={error ? error.message : null}
                  error={!!error}
                  size="small"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label={isEditForm ? t('edit_card') : t('add_card')}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            data-e2e={'key-btn-cancel'}
            onClick={cleanAddKey}
            variant="outlined"
            color="secondary"
            startIcon={<Cancel />}
          >
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-e2e={isEditForm ? 'key-btn-save' : 'key-btn-add'}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            startIcon={<AddCircle />}
          >
            {isEditForm ? t('save') : t('add')}
          </Button>
        </Grid>
      </Grid>
    </CommonContent>
  )
}

export default UsersKeyForm
