import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { CommonContent } from '../../components/CommonContent'
import { PersonAdd } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { hipUsersState } from '../store/selectors/hipUsers'
import { useEffect, useState } from 'react'
import {
  hipUserCleanErrorRequest,
  hipUserCleanSuccessRequest,
  hip_usersKeysRequest,
} from '../actions/hipUsers/actions'
import HipUserForm from '../hipUsers/HipUserForm'
import UsersKeyForm from '../hipUsers/UsersKeyForm'
import HipUsersTables from './HipUsersTables'
import { userIcon } from './utilsHipUser'
import { ProfileMapping, User_Key } from '../types'

interface HipUserViewProps {
  userType: string
}

const HipUserView = ({ userType }: HipUserViewProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { hipUsers, isLoading, error, success } = useSelector(hipUsersState)
  const [open, setOpen] = useState(false)
  const [addKey, setAddKey] = useState<{ add: boolean; id: number }>({ add: false, id: null })

  const hipUserInfo = hipUsers.filter((user: User_Key) => addKey.id === user.id)
  const cleanAddKey = () => setAddKey({ add: false, id: null })

  useEffect(() => {
    console.log(isLoading)
  }, [isLoading])

  const profileMapping: ProfileMapping = {
    nurses: [2, 3],
    maintenance: [4],
    cleaning: [5],
    roomReady: [6],
    admission: [7],
    roomService: [8],
  }

  const selected_profiles = profileMapping[userType]

  useEffect(() => {
    dispatch(hip_usersKeysRequest(selected_profiles))
  }, [userType])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(hipUserCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(hipUserCleanErrorRequest())
    }
  }, [success, error])

  const handleOpen = () => {
    setOpen(true)
    setAddKey({ add: false, id: null })
  }

  return (
    <CommonContent title={t(`${userType}`)} titleIcon={userIcon(userType)} data-e2e="nrs-title">
      {hasPrivilege(Privileges.ADD_HIP_USERS) && (
        <Box mb={4}>
          <Button
            data-e2e="nrs-ADD-btn"
            variant="contained"
            color="success"
            startIcon={<PersonAdd />}
            onClick={handleOpen}
          >
            {t(`add_${userType}`)}
          </Button>
        </Box>
      )}
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          {hipUsers.length ? (
            <HipUsersTables
              userType={userType}
              hipUsers={hipUsers}
              setAddKey={setAddKey}
              setOpen={setOpen}
            />
          ) : (
            <Typography
              data-e2e="nrs-not"
              variant="h6"
              color="text.secondary"
              align="center"
              p={10}
            >
              {t('no_items')}
            </Typography>
          )}
          {addKey.add && userType !== 'admission' && (
            <UsersKeyForm hipUserInfo={hipUserInfo[0]} cleanAddKey={cleanAddKey} addKey={addKey} />
          )}
          {open && <HipUserForm setOpen={setOpen} userType={userType} />}
        </>
      )}
    </CommonContent>
  )
}

export default HipUserView
