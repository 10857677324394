import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SSEProvider, useSSE } from 'react-hooks-sse'
import { getUserInfo } from '../../common/store/selectors/user'
import { getUserProfileState } from '../../common/store/selectors/profiles'
import { groupsStates } from '../../common/store/selectors/groups'
import { IWelcome } from '../../types'
import { IProfile } from '../../common/api/types'
import axios from 'axios'
import AdminButton from '../../dashboard/components/AdminButton'
import { Box, Button, Container, CssBaseline } from '@mui/material'
import IconsInfo from './IconsInfo'
import RoomsViews from './RoomsViews'
import ResetSystemButton from '../../dashboard/components/ResetSystemButton'
import { userProfileRequest } from '../../common/store/actions/profile/actions'
import { groupsRequest } from '../../common/store/actions/groups/actions'
import ZoneIndicator from '../../dashboard/components/ZoneIndicator'
import { SoniDevices } from '../../dashboard/components/SoniDevices'
import { roomsRequest } from '../actions/rooms/actions'

interface IRoomsBoardProps {
  isMobileMode?: boolean
}

const Welcome = () => {
  const welcome = useSSE<IWelcome>('welcome', { events: [] })
  useEffect(() => {
    // Fire devices event
    welcome.events.forEach((event) => axios.get(`/v0/api/event/${event}`))
  }, [welcome])
  return <></>
}

const RoomsBoard = ({ isMobileMode }: IRoomsBoardProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id, username } = useSelector(getUserInfo)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [onlyEvents, setOnlyEvents] = useState<boolean>(false)
  const handleToggle = () => setOnlyEvents(!onlyEvents)

  useEffect(() => {
    if (id) {
      dispatch(roomsRequest({ limit: '500', page: '1' }))
      dispatch(userProfileRequest({ user_id: id?.toString() }))
      dispatch(groupsRequest())
    }
  }, [])

  const { profiles, rooms, devices } = useSelector(getUserProfileState)
  const profile: IProfile = profiles && profiles.length > 0 ? profiles[0] : null
  const isProfile = profile ? profile.profile_id : 0

  const { groups } = useSelector(groupsStates)
  const zones = groups.map((group) => group.name)

  return (
    <SSEProvider endpoint={`/sse/events?user_id=${id}`}>
      <CssBaseline />
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          margin={isMobileMode ? 1 : 3}
        >
          {!isMobileMode && (
            <Box m={2}>
              <AdminButton />
            </Box>
          )}
          <Box display="flex">
            <SoniDevices soni_devices={devices} />
          </Box>
          <Box m={2}>
            <Button variant="contained" color="primary" type="submit" onClick={handleToggle}>
              {onlyEvents ? t('all_rooms') : t('only_events')}
            </Button>
          </Box>
          <Box>{isProfile !== 1 && <ZoneIndicator zones={zones} />}</Box>
          {!isMobileMode && <Box m={2}>{isProfile === 1 && <ResetSystemButton />}</Box>}
          <IconsInfo isDesktopMode={!isMobileMode} />
          <Welcome />
        </Box>
        <RoomsViews
          isDesktopMode={!isMobileMode}
          onlyEvents={onlyEvents}
          username={username}
          isProfile={isProfile}
          rooms={rooms}
        />
      </Container>
    </SSEProvider>
  )
}

export default RoomsBoard
