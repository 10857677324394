import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  roomsError,
  roomsRequest,
  roomsSuccess,
  roomCreateError,
  roomCreateRequest,
  roomCreateSuccess,
  roomDeleteRequest,
  roomDeleteSuccess,
  roomDeleteError,
  roomDetailRequest,
  roomDetailSuccess,
  roomDetailError,
  roomUpdateSuccess,
  roomUpdateRequest,
  roomCleanErrorRequest,
  roomUpdateError,
  roomCleanSuccessRequest,
  availableRoomsRequest,
  availableRoomsSuccess,
  availableRoomsError,
} from '../../actions/rooms/actions'
import { deletedItem, Room } from '../../types'
import { PaginatedResponse } from '../../../types'

interface RoomsState {
  room: Room
  rooms: {
    page: number | null
    limit: number | null
    pages: number | null
    total: number | null
    items: Room[]
  }
  availablesRooms: Room[]
  isLoading: boolean
  success: string
  error: string
}

export const initialState = (): RoomsState => ({
  room: {
    orderId: null,
    name: '',
    rcus: [],
    roomNo: '',
    floor: '',
  },
  rooms: {
    page: null,
    limit: null,
    pages: null,
    total: null,
    items: [],
  },
  availablesRooms: [],
  isLoading: false,
  success: '',
  error: '',
})

export const roomsReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(roomsRequest, (state) => {
      state.isLoading = true
    })
    .addCase(roomsSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const { page, limit, pages, total, items } = payload
      state.rooms = {
        page,
        limit,
        pages,
        total,
        items: items as Room[],
      }
      state.isLoading = false
    })
    .addCase(roomsError, (state, { payload }) => {
      console.error('roomsReducer - roomsError', payload)
      state.error = payload
      state.rooms = initialState().rooms
      state.isLoading = false
    })
    .addCase(availableRoomsRequest, (state) => {
      state.availablesRooms = []
    })
    .addCase(availableRoomsSuccess, (state, { payload }: PayloadAction<PaginatedResponse>) => {
      const availableRooms = payload.items as Room[]
      state.availablesRooms = availableRooms
    })
    .addCase(availableRoomsError, (state, { payload }) => {
      console.error('roomsReducer - availableRoomsError', payload)
      state.error = payload
      state.availablesRooms = []
    })
    .addCase(roomDetailRequest, (state) => {
      state.isLoading = true
    })
    .addCase(roomDetailSuccess, (state, { payload }: PayloadAction<Room>) => {
      state.room = payload
      state.isLoading = false
    })
    .addCase(roomDetailError, (state, { payload }) => {
      console.error('roomsReducer - roomDetailError', payload)
      state.error = payload
      state.room = initialState().room
      state.isLoading = false
    })
    .addCase(roomCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(roomCreateSuccess, (state, { payload }) => {
      const { page, limit, pages, total, items } = payload
      state.rooms = {
        page,
        limit,
        pages,
        total,
        items: items as Room[],
      }
      state.success = 'add_success'
      state.isLoading = false
    })
    .addCase(roomCreateError, (state, { payload }) => {
      console.error('roomsReducer - roomCreateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(roomUpdateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(roomUpdateSuccess, (state, { payload }: PayloadAction<Room>) => {
      const currentRooms = state.rooms
      const updatedRooms = {
        ...currentRooms,
        items: currentRooms.items.map((room) => (room.id === payload.id ? payload : room)),
      }
      state.rooms = updatedRooms
      state.room = payload
      state.success = 'update_success'
      state.isLoading = false
    })
    .addCase(roomUpdateError, (state, { payload }) => {
      console.error('roomsReducer - roomUpdateError', payload)
      state.error = payload.message
      state.isLoading = false
    })
    .addCase(roomDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(roomDeleteSuccess, (state, { payload }: PayloadAction<deletedItem>) => {
      const currentRooms = state.rooms
      const updatedRooms = {
        ...currentRooms,
        total: currentRooms.total ? currentRooms.total - 1 : 0,
        items: currentRooms.items.filter((room) => room.id !== Number(payload.id)),
      }
      state.rooms = updatedRooms
      state.success = 'delete_success'
      state.isLoading = false
    })
    .addCase(roomDeleteError, (state, { payload }) => {
      console.error('roomsReducer - roomDeleteError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(roomCleanSuccessRequest, (state) => {
      state.success = ''
    })
    .addCase(roomCleanErrorRequest, (state) => {
      state.error = ''
    })
})
